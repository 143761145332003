export const BAD_REQUEST = 'BAD_REQUEST';
export const RESET_VALIDATE_FIELDS = "RESET_VALIDATE_FIELDS";

// Authontication User
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNIN_USER = 'SIGNIN_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const ACTIVE_SESSION = 'ACTIVE_SESSION';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER = 'LOAD_USER';



