import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { BrowserRouter as Router, Route, Routes, Switch, Navigate, useLocation } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store'

import { RequireAuth } from './services/RequireAuth';

//App
const App = React.lazy(() => import("./components/app"));


const Custompages = React.lazy(() => import("./components/Adminauth/custompages"));
const Login = React.lazy(()=>import("./components/Adminauth/Login"));
const Register = React.lazy(()=>import("./components/Adminauth/Register"));


//Dashboard
const Dashboard = React.lazy(()=> import("./components/Dashboard/Dashboard"));
const CreateNewCustomer = React.lazy(()=> import("./components/UserManagement/CreateNewCustomer"));
const UpdateCustomerData = React.lazy(()=> import("./components/UserManagement/UpdateCustomerData"));
const CustomerProfileList = React.lazy(()=> import("./components/UserManagement/CustomerProfileList"));
const MedicalConditions = React.lazy(()=> import("./components/UserManagement/Settings/MedicalConditions"));
const DietPreference = React.lazy(()=> import("./components/UserManagement/Settings/DietPreference"));
const Allergies = React.lazy(()=> import("./components/UserManagement/Settings/Allergies"));
const HowYouFindUs = React.lazy(()=> import("./components/UserManagement/Settings/HowYouFindUs"));
const WhichMealsDoYouUsuallyHave = React.lazy(()=> import("./components/UserManagement/Settings/WhichMealsDoYouUsuallyHave"));
const PurchaseReport = React.lazy(()=> import("./components/UserManagement/PurchaseReport"));


const MealItems = React.lazy(()=> import("./components/MealsMamangement/MealItems"));
const MealType = React.lazy(()=> import("./components/MealsMamangement/MealType"));
const MealCategory = React.lazy(()=> import("./components/MealsMamangement/MealCategory"));
const ComboMealItems = React.lazy(()=> import("./components/MealsMamangement/ComboMealItems"));
const MealPackage = React.lazy(()=> import("./components/MealsMamangement/MealPackage"));
const MealPackageNew = React.lazy(()=> import("./components/MealsMamangement/MealPackageNew"));
const AddComboMealInPackage = React.lazy(()=> import("./components/MealsMamangement/SubComponent/AddComboMealInPackage"));


const CouponList = React.lazy(()=> import("./components/CouponMangement/CouponList"));

const SliderImage = React.lazy(()=> import("./components/AppManagement/SliderImage"));
const DietitianBooking = React.lazy(()=> import("./components/AppManagement/DietitianBooking"));
const Testimonial = React.lazy(()=> import("./components/AppManagement/Testimonial"));
const PurchaseNote = React.lazy(()=> import("./components/AppManagement/PurchaseNote"));
const AddressSetup = React.lazy(()=> import("./components/AppManagement/AddressSetup"));
const AdminLink = React.lazy(()=> import("./components/AppManagement/AdminLink"));
const AppBlog = React.lazy(()=> import("./components/AppManagement/AppBlog"));

const TicketType = React.lazy(()=> import("./components/TicketManagement/TicketType"));
const TicketList = React.lazy(()=> import("./components/TicketManagement/TicketList"));


const DeliverySchedule = React.lazy(()=> import("./components/KitchenReports/DeliverySchedule"));
const MealItemReport = React.lazy(()=> import("./components/KitchenReports/MealItemReport"));
const MealReport = React.lazy(()=> import("./components/KitchenReports/MealReport"));
const DeliveryReport = React.lazy(()=> import("./components/KitchenReports/DeliveryReport"));

//pages
const Profile = React.lazy(() => import("./components/pages/Profile/Profile"));

const Loaderimg = () => {
  return (
    <div id="global-loader">
      <img
        src={require("./assets/images/loader.svg").default}
        className="loader-img"
        alt="Loader"
      />
    </div>
  );
};
const Root = () => {
 

  return (
    <Provider store={store}>
      <Fragment>
        <Router>
        <React.Suspense fallback={Loaderimg()}>
          <Routes>

            <Route path={`${process.env.PUBLIC_URL}/`} element={<Custompages />} >
              <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
              <Route path={`${process.env.PUBLIC_URL}/register`} element={<Register store={store} />} />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/`} element={<App />} >
              <Route index element={<RequireAuth><Dashboard /></RequireAuth>} />
             
              <Route  path={`${process.env.PUBLIC_URL}/dashboard`} element={<RequireAuth><Dashboard /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/create-customer`} element={<RequireAuth><CreateNewCustomer /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/update-customer-data/:id`} element={<RequireAuth><UpdateCustomerData /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/customer-profile-list`} element={<RequireAuth><CustomerProfileList /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/settings/medical-conditions`} element={<RequireAuth><MedicalConditions /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/settings/diet-preference`} element={<RequireAuth><DietPreference /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/settings/allergies`} element={<RequireAuth><Allergies /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/settings/how-you-find-us`} element={<RequireAuth><HowYouFindUs /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/settings/which-meals-do-you-usually-have`} element={<RequireAuth><WhichMealsDoYouUsuallyHave /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/user-management/purchase-report`} element={<RequireAuth><PurchaseReport /></RequireAuth>}/>
             
              <Route  path={`${process.env.PUBLIC_URL}/meals-management/meal-items`} element={<RequireAuth><MealItems /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/meals-management/meal-type`} element={<RequireAuth><MealType /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/meals-management/combo-meal-items`} element={<RequireAuth><ComboMealItems /></RequireAuth>}/>
              {/* <Route  path={`${process.env.PUBLIC_URL}/meals-management/meal-package`} element={<RequireAuth><MealPackage /></RequireAuth>}/> */}
              <Route  path={`${process.env.PUBLIC_URL}/meals-management/meal-category`} element={<RequireAuth><MealCategory /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/meals-management/meal-package`} element={<RequireAuth><MealPackageNew /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/meals-management/add-combo-meal-in-package/:id`} element={<RequireAuth><AddComboMealInPackage /></RequireAuth>}/>


              <Route  path={`${process.env.PUBLIC_URL}/kitchen-report/delivery-schedule`} element={<RequireAuth><DeliverySchedule /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/kitchen-report/meal-item-report`} element={<RequireAuth><MealItemReport /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/kitchen-report/meal-report`} element={<RequireAuth><MealReport /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/kitchen-report/delivery-report`} element={<RequireAuth><DeliveryReport /></RequireAuth>}/>

              <Route  path={`${process.env.PUBLIC_URL}/coupon-management/coupon-list`} element={<RequireAuth><CouponList /></RequireAuth>}/>

              <Route  path={`${process.env.PUBLIC_URL}/app-management/slider-image`} element={<RequireAuth><SliderImage /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/app-management/dietitian-booking`} element={<RequireAuth><DietitianBooking /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/app-management/testimonial`} element={<RequireAuth><Testimonial /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/app-management/purchase-note`} element={<RequireAuth><PurchaseNote /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/app-management/address-setup`} element={<RequireAuth><AddressSetup /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/app-management/admin-link`} element={<RequireAuth><AdminLink /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/app-management/app-blogs`} element={<RequireAuth><AppBlog /></RequireAuth>}/>
            
            
              <Route  path={`${process.env.PUBLIC_URL}/ticket-management/ticket-type`} element={<RequireAuth><TicketType /></RequireAuth>}/>
              <Route  path={`${process.env.PUBLIC_URL}/ticket-management/ticket-list`} element={<RequireAuth><TicketList /></RequireAuth>}/>

              <Route path={`${process.env.PUBLIC_URL}/pages/profile`} element={<Profile />} />
           
            </Route>
 
          </Routes>
          </React.Suspense>
        </Router>
      </Fragment>
    </Provider>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
