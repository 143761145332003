import * as type from '../types';

const inititalState = {
	userdata: {},
	token: '',
	authenticated: false,
	username : '',
	errorArr: {},
	errorArrMsg: {},
	status: 0
}


const authReducer = (state = inititalState, action) => {
	switch (action.type) {
		case type.SIGNIN_USER:
        case type.SIGNUP_USER:
            return { ...state, error: null, authenticated: false };

		case type.LOGIN_USER_SUCCESS:
		case type.REGISTER_USER_SUCCESS:
				return { ...state, error: null, userdata: action.payload.data, authenticated: true, status: 200 };	

		case type.ACTIVE_SESSION:
			return { ...state, error: null, authenticated: true, token: action.payload };		

		case type.LOAD_USER_SUCCESS:
			return { ...state, error: null, authenticated: true, userdata: action.payload.data, status: 200 };	

		case type.SIGNOUT_USER:
			return { ...state, error: null, username: null, authenticated: false };	

		case type.BAD_REQUEST:
			return { ...state, errorArr: action.payload.data.data, status: action.payload.data.response, errorArrMsg:action.payload.data.message };
		default:
			return state;	
			  
	}

}

export default authReducer

