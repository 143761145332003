import { BrowserRouter as Router, Route, Routes, Switch, Navigate, useLocation } from "react-router-dom";

const getToken = (cookieName) => {
    const name = cookieName + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  
 export  function RequireAuth({ children }) {
    const authed = getToken(process.env.REACT_APP_COOKIE_NAME);
    const location = useLocation();
  
    return authed ? (
      children
    ) : (
      <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
  }
  