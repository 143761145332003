import {  createStore, applyMiddleware } from 'redux'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk';

import authReducer from './reducer/AuthenticationReducer';

const rootReducer = combineReducers({
	auth: authReducer
});

const store = createStore(rootReducer, applyMiddleware( thunk))

export default store